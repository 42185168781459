.bet-bar-wrapper {
  margin-top: 15px;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .bet-bar-wrapper {
    margin-top: 35px;
  }

  .bet {
    font-size: 14px;
  }

  .btn {
    font-size: 13px;
  }
}

@media screen and (max-height: 500px) {
  .bet-bar-wrapper {
    margin-top: 1px;
  }
  .bet {
    font-size: 13px;
  }

  .btn {
    font-size: 11px;
  }
}
