@media screen and (max-width: 600px) {
  .cards-wrapper {
    margin-top: 10px;
  }
}

@media screen and (max-height: 500px) {
  .cards-wrapper {
    margin-top: 1px;
  }
}
