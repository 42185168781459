.messages-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 95%;
  width: 95%;
  margin: 0 auto 0 auto;
  display: flex;
}

.bet {
  // display: inline-block;
  text-align: left;
  width: 100%;
  font-size: 13px;
  min-height: 18px;
  white-space: nowrap;
}
.free-coins {
  width: 100%;
  // display: inline-block;
  padding: 0;
  margin: 0;
}
.balance {
  // display: inline-block;
  text-align: right;
  width: 100%;
  font-size: 13px;
  white-space: nowrap;
  min-height: 18px;
}

.get-free-coins {
  // display: inline-block;
  color: lightgreen;
  font-weight: bold;
  margin: 11px 0 0 0;
  padding: 0;
  background: transparent;
  border: 0;
  font-size: 12px;
  cursor: pointer;
  text-shadow: 1px 1px 8px #fff, 1px 1px 8px #ccc;
}

.hidden {
  display: none !important;
}

@media screen and (max-width: 802px) {
  .messages-wrapper {
    width: 80%;
    max-width: 80%;
    // min-height: 58px;
  }

  .bet,
  .balance,
  .free-coins {
    font-size: 12px;
    // width: 100%;
  }
}

@media screen and (max-height: 500px) {
  .bet,
  .balance,
  .free-coins {
    font-size: 11px;
  }
}

@media screen and (min-width: 1200px) and (orientation: landscape) {
  .messages-wrapper {
    max-width: 55%;
    width: 55%;
  }
}
