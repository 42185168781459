body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Lucida Console", "Consolas",
    "Courier", "Courier New";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: blue;
  text-align: center;
  color: white;
}
