.title-wrapper {
  width: 100%;
  height: auto;
}

.yellow-addition {
  color: yellow;
  font-style: italic;
  font-size: 13px;
}

.beta-addition {
  color: lightgray;
  font-style: italic;
  font-size: 11px;
}

// @media screen and (max-width: 600px) and (orientation: portrait) {
@media screen and (orientation: portrait) {
  .title-wrapper {
    margin: 30px 0 25px 0;
    width: 100%;
    // height: 50px;
  }
}
