.win-tables-wrap {
  max-width: 65%;
  width: 65%;
  margin: 0 auto 0 auto;
  display: flex;
}

.win-table {
  display: table;
  font-size: 13px;
  border-spacing: 0;
  // margin: 5px 0 0 0;
  background-color: rgba(0, 0, 0, 0.12);
  width: 33%;
  // margin-left: auto;
  // margin-right: auto;
  tbody {
    tr {
      td {
        border: 1px white solid;
        border-collapse: separate;
        padding: 5px;
        // min-width: 70px;
        width: 40%;
      }
      td:first-child {
        text-align: left;
        // background-color: red;
        // background-color: blue;
        color: yellow;
        width: 60%;
      }
    }
    tr.active {
      background-color: red;
    }
  }
}

.win-combo {
  animation: blinkingBackground 2s;
  background-color: red;
}
.win-combo-mid {
  animation: blinkingBackground 5s;
  background-color: red;
}
.win-combo-long {
  animation: blinkingBackground 7s;
  background-color: red;
}

@keyframes blinkingBackground {
  0% {
    background-color: #10c018;
  }
  100% {
    background-color: #ef0a1a;
  }
}

// @media screen and (orientation: portrait) {
@media screen and (max-width: 802px) and (orientation: portrait) {
  .win-tables-wrap {
    max-width: 100%;
    width: 100%;
    display: inline-block;
  }
  .win-table {
    width: 80%;
    font-size: 13px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1200px) and (orientation: landscape) {
  .win-tables-wrap {
    max-width: 55%;
    width: 55%;
  }
}

@media screen and (max-width: 635px) and (orientation: landscape) {
  .win-tables-wrap {
    max-width: 100%;
    width: 100%;
    display: inline-block;
  }
  .win-table {
    width: 100%;
    max-width: 100%;
    font-size: 12px;
    margin: 0 auto;
  }
  .win-table tbody tr td {
    padding: 3px;
  }
}

@media screen and (min-width: 636px) and (max-width: 801px) and (orientation: landscape) {
  .win-tables-wrap {
    max-width: 80%;
    width: 80%;
  }
  .win-table tbody tr td {
    padding: 3px;
  }
}

@media screen and (max-width: 1000px) {
  .win-table {
    font-size: 11px;
  }
}
