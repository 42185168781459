.cardWrapper {
  /* background-color: white; */
  display: inline-block;
  width: 100px;
  height: auto;
  margin: 0 5px;
}
.cardImg {
  width: 100%;
  height: 100%;
}

.held-indicator-wrapper {
  height: 25px;
  background-color: transparent;
  margin: 0 3px 3px 0;
  padding: 0;
  line-height: 25px;
  vertical-align: middle;
  width: 100%;
  // background-color: rgba(0, 0, 0, 0.1);
}

.held-indicator-wrapper.active {
  background-color: yellow;
  color: blue;
}

.flip-card {
  background-color: transparent;
  width: 100px;
  height: 140px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card-active .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-card-front {
}

.flip-card-back {
  transform: rotateY(180deg);
}

.heldBtnWrapper button {
  border-radius: 3;
  // backgroundColor: "#ff0000",
  padding: 3px 24px;
  font-size: 12px;
  color: #ffffff;
  margin-top: 3px;
}

// @media screen and (max-width: 600px) and (orientation: portrait) {
@media screen and (max-width: 600px), screen and (max-height: 500px) {
  .cardWrapper {
    /* background-color: white; */
    display: inline-block;
    width: 60px;
    height: auto;
    margin: 0 2px;
  }

  .held-indicator-wrapper {
    height: 20px;
    background-color: transparent;
    margin: 0 3px 3px 0;
    padding: 0;
    line-height: 20px;
    vertical-align: middle;
    width: 60px;
    font-size: 11px;
    // background-color: rgba(0, 0, 0, 0.1);
  }

  .cardImg {
    width: 60px;
    height: 85px;
  }
  .flip-card {
    width: 60px;
    height: 85px;
  }

  .flip-card-front,
  .flip-card-back {
    width: 60px;
    height: 85px;
  }

  .heldBtnWrapper button {
    min-width: 60px;
    height: 35px;
    // backgroundColor: "#ff0000",
    padding: 0;
    font-size: 11px;
  }
}

@media screen and (max-height: 500px) {
  .cardWrapper {
    /* background-color: white; */
    display: inline-block;
    width: 60px;
    height: auto;
    margin: 0 4px;
  }

  .held-indicator-wrapper {
    height: 17px;
    line-height: 17px;
  }

  .heldBtnWrapper button {
    min-width: 60px;
    height: 25px;
    // backgroundColor: "#ff0000",
    padding: 0;
    font-size: 11px;
  }
}
